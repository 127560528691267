import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login.vue'
// import index from '../views/index.vue'
Vue.use(VueRouter)


let routes = [{
		path: '/login',
		name: 'Login',
		component: Login
	},
	{
		path: '*',
		name: '404',
		component: resolve => require(['../views/404'], resolve)
	},

	{
		path: '/',
		redirect: '/home',
	},
	{
		path: '/home',
		name: 'home',
		component: resolve => require(['../views/home/home'], resolve),
		children: [{
				path: '/orders',
				name: '订单列表',
				component: resolve => require(['../views/orderList.vue'], resolve)
			},
			{
				path: '/printers',
				name: '打印机',
				component: resolve => require(['../views/printers.vue'], resolve)
			},
			{
				path: '/mydata',
				name: '我的数据',
				component: resolve => require(['../views/myData.vue'], resolve)
			},
			{
				path: '/shop',
				name: '店铺设置',
				component: resolve => require(['../views/shopSet.vue'], resolve)
			},
		]
	}, {
		path: '/h5',
		name: 'h5',
		component: resolve => require(['../views/h5/h5home'], resolve),
		children: [{
				path: '/h5Me',
				component: resolve => require(['../views/h5/me.vue'], resolve)
			}, {
				path: '/h5Shop',
				component: resolve => require(['../views/h5/shop.vue'], resolve)
			}, {
				path: '/h5printers',
				component: resolve => require(['../views/h5/printers.vue'], resolve)
			}, {
				path: '/h5Orders',
				component: resolve => require(['../views/h5/orders.vue'], resolve)
			}

		]
	},
	{
		path: '/jiaocheng',
		component: resolve => require(['../views/h5/jiaocheng.vue'], resolve)
	},
	{
		path: '/h5upload',
		component: resolve => require(['../views/h5/h5upload.vue'], resolve)
	}, {
		path: '/h5phone',
		component: resolve => require(['../views/h5/h5phone.vue'], resolve)
	}





]
//动态加载h5路由
let router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
