import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import aes from './utils/aes.js'
import util from './utils/util.js'



import axios from "axios"
Vue.prototype.$http = axios

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

let APIURL = "blwyapi.mmteck.cn";
axios.interceptors.request.use(async req => {
	
	if (!req.data) {
		req.data = {}
	}
	try {


		//生成 c s f r 校验
		let v = String.fromCharCode(90 + 9) + String.fromCharCode(100 + 15) + String.fromCharCode(100 + 7 *
				2) +
			String.fromCharCode(51 * 2)
		let c = {}
		let d = new Date()
		c[v] = d.getTime() + "_" + parseInt(Math.random() * 100000);
		c[v] = aes.encrypt(c[v])
		let hour = d.getHours(),
			min = d.getMinutes(),
			sec = d.getSeconds();
		hour < 10 ? hour = '0' + hour : true;
		min < 10 ? min = '0' + min : true;
		sec < 10 ? sec = '0' + sec : true;
		c["_" + v] = aes.encrypt(hour + ":" + min + ":" + sec)
		req.headers[v] = c[v] + "_" + c["_" + v]
		if (localStorage.getItem("blwytoken")) {
			req.headers["Authorization"] =localStorage.getItem("blwytoken")
		}
		if (req.url.indexOf("http") >= 0) {

		} else {
			if (window.location.href.indexOf("localhost1") > 0) {
				req.url = "//127.0.0.1:10010" + req.url
			} else {
				req.url = "//" + APIURL + req.url
			}

		}
		if (!req.data.noloading) {
			$("#loading").show();
		}
	} catch (e) {
		console.log(e)
	}

	return req
}, error => {
	return Promise.reject(error)
});
axios.interceptors.response.use(response => {
	$("#loading").hide();
	if (response.data.code == 401) {
		localStorage.removeItem('token')
		router.push('/login')
	} else {
		if (response.data.code && response.data.code != 200) {
			$("#errdg").html(response.data.msg).show();
			setTimeout(() => {
				$("#errdg").hide()
			}, 3000)
			return Promise.reject(null)
		} else {
			return response
		}

	}
}, error => {
	if (error.response.status == 401) {
		router.push('/login')
	} else {
		return Promise.reject(error)
	}

})







Vue.prototype.uploadTemp = (fileObj) => {
	var myDate = new Date();
	var ossPath = 'files/' + myDate.getFullYear() + "-" + (myDate.getMonth() + 1);
	// 获取文件后缀
	var pathArr = fileObj.file.name.split('.')
	//  随机生成文件名称
	var fileRandName = Date.now() + "" + parseInt(Math.random() * 10000)
	var fileName = fileRandName + '.' + pathArr[pathArr.length - 1]
	// 要提交的key
	var fileKey = ossPath + "/" + fileName.toLowerCase();
	let formData = {
		name: fileObj.file.name,
		key: fileKey,
		policy: 'eyJleHBpcmF0aW9uIjoiMjAzNS0wMS0wMlQxMjowMDowMC4wMDBaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMTA0ODU3NjAwMF1dfQ==',
		OSSAccessKeyId: 'LTAI0Dd9MsOqnVoj',
		signature: 'Xst4hA4qiPYO5HXG/8CcQgDTm9U=',
		success_action_status: "200",

	};
	var host = 'https://mmtemp.oss-cn-hangzhou.aliyuncs.com';
	var outurl = host
	var form = new FormData();
	// 其他参数
	for (let k in formData) {
		form.append(k, formData[k]);
	}
	form.append("file", fileObj.file);
	// XMLHttpRequest 对象
	var xhr = new XMLHttpRequest();
	xhr.open("post", host, true);
	xhr.upload.addEventListener("progress", fileObj.onProgress, false); //监听上传进度
	xhr.onload = (res) => {
		if (fileObj.onSuccess) {


			fileName = fileName.toLocaleLowerCase();
			//计算图片高度
			if (fileName.indexOf(".jpg") > 0 || fileName.indexOf(".jpeg") > 0 || fileName.indexOf(".png") > 0 ||
				fileName
				.indexOf(
					".gif") > 0 || fileName.indexOf(".bmp") > 0) {

				var reader = new FileReader();
				reader.readAsDataURL(fileObj.file);
				reader.onload = function(theFile) {
					var image = new Image();
					image.src = theFile.target.result;
					image.onload = function() {
						fileObj.onSuccess({
							src: outurl + "/" + fileKey + "?s=" + this.width + "_" + this
								.height,
							fileName: fileObj.file.name
						})

					};
				};

			} else {
				fileObj.onSuccess({
					src: outurl + "/" + fileKey,
					fileName: fileObj.file.name
				})
			}

		}
	};
	xhr.onerror = res => {
		if (fileObj.onError) {
			fileObj.onError(res)
		}

	}
	xhr.send(form);

}



Vue.prototype.uploadOss = (fileObj) => {
	$("#loading").show();
	setTimeout(function() {
		$("#loading").hide();
	}, 2000)
	var myDate = new Date();
	var ossPath = 'files/' + myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate();
	// 获取文件后缀
	var pathArr = fileObj.file.name.split('.')
	//  随机生成文件名称
	var fileRandName = Date.now() + "" + parseInt(Math.random() * 1000)
	var fileName = fileRandName + '.' + pathArr[pathArr.length - 1]
	// 要提交的key
	var fileKey = ossPath + "/" + fileName;
	let formData = {
		name: fileObj.file.name,
		key: fileKey,
		policy: 'eyJleHBpcmF0aW9uIjoiMjAzNS0wMS0wMlQxMjowMDowMC4wMDBaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMTA0ODU3NjAwMF1dfQ==',
		OSSAccessKeyId: 'LTAI0Dd9MsOqnVoj',
		signature: 'Xst4hA4qiPYO5HXG/8CcQgDTm9U=',
		success_action_status: "200",
		appname:"bilinweiyin"

	};
	var host = 'https://bilinweiyin.oss-cn-hangzhou.aliyuncs.com';
	
	var outurl = host
	var form = new FormData();
	// 其他参数
	for (let k in formData) {
		form.append(k, formData[k]);
	}
	form.append("file", fileObj.file);
	// XMLHttpRequest 对象
	var xhr = new XMLHttpRequest();
	xhr.open("post", host, true);
	xhr.upload.addEventListener("progress", fileObj.onProgress, false); //监听上传进度
	xhr.onload = (res) => {
		if (fileObj.onSuccess) {
			

			fileName = fileName.toLocaleLowerCase();
			//计算图片高度
			if (fileName.indexOf(".jpg") > 0 || fileName.indexOf(".jpeg") > 0 || fileName.indexOf(".png") > 0 ||
				fileName
				.indexOf(
					".gif") > 0 || fileName.indexOf(".bmp") > 0) {

				var reader = new FileReader();
				reader.readAsDataURL(fileObj.file);
				reader.onload = function(theFile) {
					var image = new Image();
					image.src = theFile.target.result;
					image.onload = function() {
						fileObj.onSuccess({
							src: outurl + "/" + fileKey + "?s=" + this.width + "_" + this
								.height,
							fileName: fileObj.file.name
						})

					};
				};

			} else {
				fileObj.onSuccess({
					src: outurl + "/" + fileKey,
					fileName: fileObj.file.name
				})
			}

		}
	};
	xhr.onerror = res => {
		if (fileObj.onError) {
			fileObj.onError(res)
		}

	}
	xhr.send(form);

}
Vue.prototype.waitLogin = _ => {
	return new Promise(resolve => {
		if (localStorage.getItem("blwytoken")) {
			resolve(true)
		} else {
			let t = setInterval(() => {
				if (localStorage.getItem("blwytoken")) {
					clearInterval(t)
					resolve(true)
				}
			}, 100);
		}
	}, reject => {})
}

Vue.prototype.checkUrlSign = wx=>{
	axios.post("/api/wxshare", {
		url: window.location.href
	}).then(res => {
		let checksign = opt => {
			if (wx && wx.ready) {
				wx.config({
					debug: false,
					appId: res.data.appId,
					timestamp: res.data.timestamp,
					nonceStr: res.data.nonceStr,
					signature: res.data.signature,
					jsApiList: ["getLocation"]
				});
				
				return 1
			}else{
				return 0
			}
		}
		let t = setInterval(()=>{
			if(checksign()){
				clearInterval(t)
			}else{
				console.log("验证中")
			}
		},200)
		
	})
}
//加载其他方法
for (let k in util) {
	Vue.prototype[k] = util[k]
}


new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
