let digitUppercase = function(n) {
	var fraction = ['角', '分'];
	var digit = [
		'零', '壹', '贰', '叁', '肆',
		'伍', '陆', '柒', '捌', '玖'
	];
	var unit = [
		['元', '万', '亿'],
		['', '拾', '佰', '仟']
	];
	var head = n < 0 ? '欠' : '';
	n = Math.abs(n);
	var s = '';
	for (var i = 0; i < fraction.length; i++) {
		s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
	}
	s = s || '整';
	n = Math.floor(n);
	for (var i = 0; i < unit[0].length && n > 0; i++) {
		var p = '';
		for (var j = 0; j < unit[1].length && n > 0; j++) {
			p = digit[n % 10] + unit[1][j] + p;
			n = Math.floor(n / 10);
		}
		s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
	}
	return head + s.replace(/(零.)*零元/, '元')
		.replace(/(零.)+/g, '零')
		.replace(/^整$/, '零元整');
}

let digit2Zhcn = function(n) {
	var fraction = ['', ''];
	var digit = [
		'0', '一', '二', '三', '四',
		'五', '六', '七', '八', '九'
	];
	n = n + ""
	let arr = []
	for (let i = 0; i < n.length; i++) {
		arr.push(digit[n[i]])
	}

	return arr.join("")
}

let asyncLocalStorage = {
	setItem: function(key, value) {
		return Promise.resolve().then(function() {
			localStorage.setItem(key, value);
		});
	},
	getItem: function(key) {
		return Promise.resolve().then(function() {
			return localStorage.getItem(key);
		});
	}
};

let get4Num = (n) => {
	let m = String(n);
	let l = m.length;
	for (let i = 0; i < 4 - l; i++) {
		m = '0' + m
	}
	return m
}

let get3Num = (n) => {
	let m = String(n);
	let l = m.length;
	for (let i = 0; i < 3 - l; i++) {
		m = '0' + m
	}
	return m
}

let xs2wei = (n) => {
	let m = String(n);
	if (m.length > 0) {
		if (m.indexOf(".") > 0) {
			let zheng = m.split(".")[0]
			let fen = m.split(".")[1]
			if (fen.length == 1) {
				fen = fen + "0"
			} else if (fen.length > 2) {
				fen = fen.substr(0, 2)
			} else if (fen.length == 2) {
				if (fen[1] >= 5) {
					let num = parseInt(fen)
					num += 1;
					if (num == 100) {
						zheng = parseInt(zheng) + 1;
						fen = "00"
					} else {
						fen = num
					}
				}
			}
			return zheng + "." + fen
		} else {
			return m + ".00"
		}
	} else {
		return "0.00"
	}

}


let getCity = (n) => {
	let c = "";
	if (n) {
		let arr = n.split(",")
		if (arr.length >= 3) {
			c = arr[1]
		} else if (arr.length >= 2) {
			c = arr[1]
		} else {
			c = n
		}
	}
	return c
}
import axios from "axios"
let checkUrlSign = wx => {
	axios.post("/api/get_url_wxsign", {
		url: window.location.href
	}).then(res => {
		let checksign = opt => {
			if (wx && wx.ready) {
				wx.config({
					debug: false,
					appId: res.data.appId,
					timestamp: res.data.timestamp,
					nonceStr: res.data.nonceStr,
					signature: res.data.signature,
					jsApiList: ["uploadImage", "previewImage", "chooseImage"]
				});

				return 1
			} else {
				return 0
			}
		}
		let t = setInterval(() => {
			if (checksign()) {
				clearInterval(t)
			} else {
				console.log("验证中")
			}
		}, 200)

	})
}

let getDateStr = d => {
	if (d) {
		d = new Date(d)
	}
	let year = d.getFullYear();
	let month = d.getMonth() + 1;
	let day = d.getDate();
	month < 10 ? month = '0' + month : true
	day < 10 ? day = '0' + day : true

	return year + "-" + month + "-" + day


}


var $$alert = function(options) {
	if (typeof options == "string" || typeof options == "number") {
		options = {
			title: "提示",
			msg: options,
			oktxt: "确定",
			okcb: null
		}
	} else if (typeof options == "object") {
		if (!options.hasOwnProperty("title")) {
			options.title = "提示";
		}
		if (!options.hasOwnProperty("msg")) {
			options.msg = "";
		}
		if (!options.hasOwnProperty("oktxt")) {
			options.oktxt = "确定";
		}
		if (!options.hasOwnProperty("okcb")) {
			options.okcb = null;
		}
	}
	var html = '';
	html += '<div class="walert" style=" display: block;">';
	html += '<div class="tblank"></div>';
	html += '<div class="alert">';
	html += '<img src="./images/lingdang.png" class="alerticon" />';
	html += '<div class="alerttitle">' + options.title + '</div>';
	html += '<div class="alertcontent"><div class="wico"></div>' + options.msg + '</div>';
	html += '<div class="alertbtn flex">';
	html += '<div class="okBtn flex">' + options.oktxt + '</div>';
	html += '</div></div></div>';
	$("body").append(html);
	setTimeout(function() {
		$(".okBtn").click(function() {
			if (options.okcb) {
				options.okcb();
			}

			$(".walert").animate({
				opacity: 0
			}, 300, function() {
				$(".walert").remove()
			});
		});

	}, 300)

}

var $$confirm = function(options) {
	if (typeof options == "string" || typeof options == "number") {
		options = {
			title: "提示",
			msg: options,
			oktxt: "确定",
			okcb: null,
			canceltxt: "取消",
			cancelcb: null
		}
	} else if (typeof options == "object") {
		if (!options.hasOwnProperty("title")) {
			options.title = "提示";
		}
		if (!options.hasOwnProperty("msg")) {
			options.msg = "";
		}
		if (!options.hasOwnProperty("oktxt")) {
			options.oktxt = "确定";
		}
		if (!options.hasOwnProperty("okcb")) {
			options.okcb = null;
		}
		if (!options.hasOwnProperty("canceltxt")) {
			options.canceltxt = "取消";
		}
		if (!options.hasOwnProperty("cancelcb")) {
			options.cancelcb = null;
		}
	}
	var html = '';
	html += '<div class="walert" style=" display: block;">';
	html += '<div class="tblank"></div>';
	html += '<div class="alert">';
	html += '<img src="./images/lingdang.png" class="alerticon" />';
	html += '<div class="alerttitle">' + options.title + '</div>';
	html += '<div class="alertcontent"><div class="wico"></div>' + options.msg + '</div>';
	html += '<div class="alertbtn flex">';
	html += '<div class="cancelBtn flex">' + options.canceltxt + '</div>';
	html += '<div class="confirmBtn flex">' + options.oktxt + '</div>';
	html += '</div></div></div>';
	$("body").append(html);
	$(".confirmBtn").click(function() {
		if (options.okcb) {
			options.okcb();
		}
		$(".walert").animate({
			opacity: 0
		}, 300, function() {
			$(".walert").remove()
		});
	});
	$(".cancelBtn").click(function() {
		if (options.cancelcb) {
			options.cancelcb();
		}
		$(".walert").animate({
			opacity: 0
		}, 300, function() {
			$(".walert").remove()
		});
	});

}
var $$Toast = function(msg) {
	if (msg != null && msg != "") {
		$("body").append("<div  class='lotoast'>" + msg + "</div>");
		setTimeout(function() {
			$(".lotoast").animate({
				opacity: 0
			}, 600, "linear", function() {
				$(".lotoast").remove();
			})
		}, 2000)
	}
}

export default {
	digitUppercase,
	asyncLocalStorage,
	get4Num,
	get3Num,
	getCity,
	checkUrlSign,
	getDateStr,
	digit2Zhcn,
	xs2wei,
	$$alert,
	$$Toast,
	$$confirm

}
