<template>
	<div class="loginbg"  style="background-image: url(./images/bg.jpg);">
		<div style="width: 70%;height: 100%;overflow: auto;">
			<div style="width: 600px;margin: 0 auto; padding-top:50px">
				<div style="color: orangered;font-size: 30px;font-weight: bold;text-align: center;">
					<img  :src="'./images/logo.jpg'" style="border-radius: 50%;width: 100px;"/>
				</div>
				<div style="color: orangered;font-size: 30px;font-weight: bold;text-align: center;">比邻微印是什么</div>
				<div style="color: #;font-size: 20px;font-weight: bold; color:#000;">
					比邻微印是通过安装电脑端软件，将你的打印机共享到物联网中，
				让你的家人、小区住户等人群付费或者免费使用你的打印机，将你闲置的打印机变废为宝，替你赚钱！
				</div>
				<div style="color: orangered;font-size: 30px;font-weight: bold;text-align: center;">收费么？</div>
				<div style="color: #;font-size: 20px;font-weight: bold; color:#000;">
					软件全部<span style="color: orangered;">免费</span>使用！非家庭组成员使用打印机产生的费用将暂存在平台中，可以随时手动提现！
				</div>
				<div style="color: orangered;font-size: 30px;font-weight: bold;text-align: center;">怎么用？</div>
				<div style="color: #;font-size: 20px;font-weight: bold; color:#000;">
					只要下载安装打印端软件到电脑中即可实现任意地点打印文件，支持wifi或者非wifi各种打印机
				</div>
				<div style="color: orangered;font-size: 30px;font-weight: bold;text-align: center;">注册一下？</div>
				<div style="color: #;font-size: 20px;font-weight: bold; color:#000;">
					使用微信扫码右侧二维码即可注册成功，不需要验证任何信息！
				</div>
			</div>
		</div>
		<div class="login_box">
			<div style="text-align: center; padding-bottom: 20px; font-size: 24px; font-weight: bold;">比邻微印</div>
			<div >
				<div style="width: 60%; margin: 0 auto; padding-top: 30px;">
					<img :src="qrcodeUrl?qrcodeUrl:''" alt="" style="width: 100%;">
				</div>
				<div style="text-align: center; padding-top: 20px;">
					使用微信扫码登录
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isShowModal: false,
				showbtn: false,
				bgidx: "",
				bgvideourl:"",
				EditItem: {},
				username: "",
				password: "",
				session_id: "",
				yzmUrl: "",
				yzm: "",
				showQrcode: false,
				qrcodeUrl: "",
				qrcodeChecking: false,
				qrcodeTimer: -1,
				pwdForm: {
					newPwd1: "",
					newPwd2: "",
				}
			}
		},
		mounted() {

			this.getQrcode()
		},
		methods: {
		
			getQrcode() {
				this.$http.post("/api/create_qrcode_temp", {
				}).then(res => {

					this.session_id = res.data.session_id;

					this.qrcodeUrl = res.data.qrcode;
					if (!this.qrcodeChecking) {
						this.qrcodeChecking = true

						this.checkQrcode();
					}
				})



			},
			checkQrcode() {

				this.qrcodeTimer = setInterval(() => {

					this.$http.post("/api/check_scan_qrcode", {
						session_id: this.session_id,
						noloading: 1
					}).then(res => {
						if (res.data.token) {
							clearInterval(this.qrcodeTimer)
							localStorage.setItem("blwytoken",res.data.token)
							this.$router.push("/")

						}
					})
				}, 1000)
			},
		

		}
	}
</script>

<style scoped>
	.loginbg {
		width: 100%;
		height: 100%;
		background-size: cover;
	}

	.login_box {
		position: absolute;
		top: 50%;
		right: 5%;
		margin-left: -180px;
		margin-top: -270px;
		border-radius: 4px;
		-moz-border-radius: 4px;
		-webkit-border-radius: 4px;
		background-color: #fff;
		width: 320px;
		height: 340px;
		padding: 20px;
		box-shadow: 0 2px 10px #999;
		-moz-box-shadow: #999 0 2px 10px;
		-webkit-box-shadow: #999 0 2px 10px;
	}

	.coricon {
		position: absolute;
		top: 0;
		right: 0;
		;
		width: 60px;
		height: 60px;
	}

	.coricon img {
		width: 100%;
		transform: scale(0.5, 0.5);
	}
	.bgvideo{ position: fixed; width: 100%; height: 100%; top:-10; left: 0; z-index: -100;}
</style>
