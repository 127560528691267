import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		pmid:window.pmid,
		shopInfo:{},
		exprise:false
	},
	mutations: {
		setShopInfo(state,value){
			state.shopInfo = value
		},
		setPmid(state,value){
			state.pmid = value
		},
		setExprise(state,value){
			state.exprise = value
		},
	},
	actions: {
		setShopInfo(context,value){
			context.commit("setShopInfo",value);
		},
		setPmid(context,value){
			context.commit("setPmid",value);
		},
		setExprise(context,value){
			context.commit("setExprise",value);
		}
	},
	modules: {}
})
